<template>
    <div class="box">
        <div class="bgc-box">
            <div>
                <div class="permission"></div>
                <el-button class="btn" @click="retrunUac">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        window.addEventListener('beforeunload', e => {
            sessionStorage.clear('error');
        });
    },
    destroyed() {
        window.removeEventListener('beforeunload');
    },
    methods: {
        retrunUac() {
            sessionStorage.clear('error');
            this.$router.replace('/login')
        },
    },
};
</script>

<style scoped lang="stylus">
.box
    width 100vw
    height 100vh
    background-color #fff
    display flex
    align-items center
    justify-content center

.bgc-box
    text-align center
    .permission
        width 448px
        height 414px
        background: url('~@/assets/img/error.png');
        background-size 100% 100%
        box-sizing border-box
    .login-out
        width 224px
        height 236px
        // background: url('~@/assets/img/loginout.png');
        background-size 100% 100%
        box-sizing border-box
</style>
